<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Financial Accounts</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
         <li class="breadcrumb-item">
            <router-link :to="{ name: 'Financials' }"
              >Financial</router-link
            >
          </li> 
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Financial-Accounts-List' }"
              >Accounts</router-link
            >
          </li>
          <li class="breadcrumb-item active">Edit</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-md-9">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Edit The Financial Account</h4>
          </div>
          <div class="card-body">
            <!-- FeedBack -->
            <feedback-messages :msgs="msgs" :errors="errors" />
            <!-- /FeedBack -->
            <div class="basic-form">
              <form
                id="financial_account_edit"
                @submit.prevent="submitFormData()"
              >
                <div class="form-group row">
                  <label class="col-md-3 col-form-label"
                    >Type<span style="color: red">*</span></label
                  >
                  <div class="col-md-9">
                    <select class="form-control" name="type" v-model="financial_account.type" required>
                      <option value="" disabled selected>
                        Nothing Selected
                      </option>
                      <option
                        v-for="(type, i) in lists.types"
                        :key="i"
                        :value="type"
                      >
                        {{ type }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label"
                    >Name<span style="color: red">*</span></label
                  >
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="name"
                      v-model="financial_account.name"
                      required
                      class="form-control"
                      placeholder="Name"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label"
                    >ID Number<span style="color: red">*</span></label
                  >
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="id_number"
                      v-model="financial_account.id_number"
                      required
                      class="form-control"
                      placeholder="ID Number"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label"
                    >Currency<span style="color: red">*</span></label
                  >
                  <div class="col-md-9">
                    <select class="form-control" name="currency_id" v-model="financial_account.currency_id" required>
                      <option value="" disabled selected>
                        Nothing Selected
                      </option>
                      <option
                        v-for="(currency, i) in lists.currencies"
                        :key="i"
                        :value="currency.id"
                      >
                        {{ currency.name }} [{{ currency.abbreviation }}]
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Branch Name<span style="color: red">*</span></label>
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="branch_name"
                      v-model="financial_account.branch_name"
                      required
                      class="form-control"
                      placeholder="Branch Name"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Phone<span style="color: red">*</span></label>
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="phone"
                      v-model="financial_account.phone"
                      required
                      class="form-control"
                      placeholder="Phone"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Email<span style="color: red">*</span></label>
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="email"
                      v-model="financial_account.email"
                      required
                      class="form-control"
                      placeholder="Email"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Address<span style="color: red">*</span></label>
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="address"
                      v-model="financial_account.address"
                      required
                      class="form-control"
                      placeholder="Address"
                    />
                  </div>
                </div>

                <div
                  class="form-group row"
                  style="padding-top: 2rem; margin-bottom: 0px"
                >
                  <div class="col-md-12">
                    <button
                      :disabled="disableBtn"
                      name="submit"
                      type="submit"
                      class="btn btn-primary"
                      value="Submit"
                      onclick="this.blur();"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackMessages from "@/components/FeedbackMessages.vue";
import useFinancialAccounts from "@/composables/financial_accounts";
import { ref, inject, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  components: {
    FeedbackMessages,
  },
  setup(props) {
    const disableBtn = ref(false);
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();

    const {
      getFinancialAccount,
      updateFinancialAccount,
      financial_account,
      getListsForFinancialAccounts,
      lists,
      msgs,
      errors,
    } = useFinancialAccounts();

    onBeforeMount( async() => {
      //first: check the user Permission
      if (!hasPermission("edit_financial_accounts"))
        router.push({
          name: "401",
        });

      await getFinancialAccount(props.id);
      await getListsForFinancialAccounts();
    });

    const submitFormData = async () => {
      var formDataObj = new FormData();
      const form = document.querySelector("#financial_account_edit");
      Array.from(form.elements).forEach((input) => {
        formDataObj.append(input.name, input.value);
      });

      swal.fire({
        title: "Please wait while submit your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      errors.value = [];
      disableBtn.value = true;

      await updateFinancialAccount(formDataObj, props.id).then((resp) => {
        swal.close();
        if (resp != "fail") {
          router.push({
            name: "Financial-Accounts-Single",
            params: {
              id: resp,
            },
          });
        }
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
    };

    return {
      submitFormData,
      financial_account,
      disableBtn,
      lists,
      msgs,
      errors,
    };
  },
};
</script>